(function ($, window, document, undefined) {

  'use strict';

  $(function () {

    var header = $("footer");
    header.addClass("hide");
    
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
  
        if (scroll >= 1500) {
            header.removeClass("hide");
        } else {
            header.addClass("hide");
        }
    });

    $("html").mousemove(function(event) {
     
      //changePosition(event.pageX, event.pageY, 3, $("img.logo2"));
      //var xy = getRandomPosition(img);

      var colours = ['white','purple-alt','blue','orange','pink','purple','green','yellow'];
      
      var colour = colours[Math.floor(Math.random()*8)];

      var img = document.createElement("div");
      //img.src = "assets/img/logo.svg";
      var src = document.querySelector('#background');
      img.style["top"] = event.pageY + 'px';
      img.style["left"] = event.pageX + 'px';
      img.classList.add('bandg');
      img.classList.add(colour);
      src.appendChild(img);

    });

    $("html").click(function(event) {
    
      var img = document.createElement("div");
      var src = document.querySelector('#background');
      img.style["top"] = event.pageY + 'px';
      img.style["left"] = event.pageX + 'px';
      img.classList.add('logo-main');
      src.appendChild(img);

    });

    function changePosition(x, y, speed, elm) {
      var cur_top = -150;
      var cur_left = -200;
      var new_top = cur_top + y / (speed * 10);
      var new_left = cur_left + x / (speed * 10);
      if (new_left >= 0) {
        new_left = 0;
      }
      if (new_top >= 0) {
        new_top = 0;
      }
      elm.css("left", new_left);
      elm.css("top", new_top);
    }
  });

})(jQuery, window, document);




function getRandomPosition(element) {
	var x = document.body.offsetHeight-element.clientHeight;
	var y = document.body.offsetWidth-element.clientWidth;
	var randomX = Math.floor(Math.random()*x);
	var randomY = Math.floor(Math.random()*y);
	return [randomX,randomY];
}